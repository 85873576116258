















































import Vue from 'vue';
import { Route } from 'vue-router';
import {
  VAppBar,
  VBtn,
  VMain,
  VAppBarTitle,
  VAppBarNavIcon,
} from 'vuetify/lib/components';
import { mapGetters } from 'vuex';
import TheSidebar from '../components/TheSidebar.vue';

export default Vue.extend({
  name: 'with-app-bar',
  components: {
    VAppBar,
    VBtn,
    VAppBarTitle,
    VAppBarNavIcon,
    VMain,
    TheSidebar,
  },
  data: () => ({
    drawer: false,
    currentPage: 'Home' as string | null| undefined,
  }),
  mounted() {
    this.currentPage = this.$route.name;
  },
  watch: {
    '$route': function (to: Route, from) {
      (this as any).currentPage = to.name
    }
  },
  computed: mapGetters('auth', ['user'])
});
