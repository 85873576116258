/* eslint-disable @typescript-eslint/explicit-module-boundary-types */


export const loadView = (file: string) => {
  return () => import(
    /* webpackChunkName: "view-[request]" */
    /* webpackMode: "lazy" */
    `@/views/${file}`
  )
}

export const loadComponent = (file: string) => {
  return () => import(
    /* webpackChunkName: "component-[request]" */
    /* webpackMode: "lazy" */
    `@/components/${file}`
  )
}