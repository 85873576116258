import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import PortalVue from 'portal-vue'
import VueMeta from 'vue-meta'
import '@mdi/font/css/materialdesignicons.css'
import installFilters from './plugins/filters'

Vue.config.productionTip = false

// console.log('process.env.API_BASE_URL', process.env);
Vue.use(PortalVue)

installFilters();

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
