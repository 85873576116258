import { Module } from "vuex";
import { User } from "@/models/interfaces/User";
import api from "@/api/axios";
import { login } from "@/api/auth";
import { LoginRequest } from "@/models/interfaces/requests/LoginRequest";
import { getAuthToken, getUser, setAuthToken, setUser, removeAuthToken, removeUser } from "@/common/auth";
import { GetUser } from "@/api/user";
import { deleteFcmToken } from "@/firebase";

export interface State {
  user: User | null,
  token: string | null,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthModule: Module<State, any> = {
  namespaced: true,
  state: (): State => ({
    user: getUser(),
    token: getAuthToken(),
  }),
  getters: {
    token(state): string | null {
      return state.token;
    },
    user(state): User | null {
      return state.user;
    },
    userIsAuthenticated: state => !!state.token
  },
  mutations: {
    set(state, data) {
      state.user = data.user;
      state.token = data.token;
      setAuthToken(data.token);
      setUser(data.user);
    },
    clear(state) {
      state.user = null;
      state.token = null;
      removeAuthToken();
      removeUser();
    },
  },
  actions: {
    async reloadUser({ state, commit }, catchException = true) {
      try {
        const res = await GetUser();
        commit('set', {
          user: res.data,
          token: state.token
        });
      } catch (err) {
        console.error(err);
        if (!catchException) {
          throw err;
        }
      }
    },
    async verifyPhone({ commit }, phone) {
      try {
        const response = await api.post('v1/validateexistphone', { phone });
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    login({ commit }, loginData: LoginRequest) {
      return login(loginData);
    },
    async logout({ commit }) {
      // const success: boolean = await deleteFcmToken()
      // console.log('se eliminó el token? ', success)
      return new Promise((resolve) => {
        commit('clear');
        resolve(true);
      })
    }
  }
}
