import { User } from "@/models/interfaces/User";
import { AUTH_TOKEN_KEY, AUTH_USER_KEY } from '@/common/constants/auth'
import api from "@/api/axios";


export function getAuthToken(): string | null {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  if (token) {
    api.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`;
  }
  return token;
}

export function setAuthToken(token: string): void {
  api.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${token}`;
  console.log("Token set")
  localStorage.setItem(AUTH_TOKEN_KEY, token);
}

export function removeAuthToken(): void {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  delete api.defaults.headers.common['Authorization'];
}

// User CRUD
export function getUser(): User | null {
  return JSON.parse(localStorage.getItem(AUTH_USER_KEY) || '{}');
}

export function setUser(user: User): void {
  localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
}

export function removeUser(): void {
  localStorage.removeItem(AUTH_USER_KEY);
}
