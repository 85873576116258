// Poner aquí las funciones que se pueden llegar a utilizar en varios lados

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mergeImages from "merge-images-v2";

// Detects if device is on iOS
export function GetIsIos() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}

// Detects if device is in standalone mode
export function GetIsStandalone() {
  return ('standalone' in window.navigator) && ((window.navigator as any)?.standalone)
}

/**
 * Gets the response errors from failed response exception
 * @param exception failed response exception
 */
export function GetResponseErrors(error: any): string[] {
  let res = error;
  let messages: string[] = []
  if (error.response) {
    res = error.response
  } else {
    return error.toString();
  }
  const data = res.data;
  if (data.errors) {
    for (const errorKey of Object.keys(data.errors)) {
      let errs = [];
      if (Array.isArray(data.errors[errorKey])) {
        errs = data.errors[errorKey];
      }
      if (typeof data.errors[errorKey] == 'string') {
        errs = [data.errors[errorKey]];
      }
      messages = [...messages, ...errs];
    }
  } else if (data?.exception) {
    messages.push(data?.exception);
  } else if (data?.message) {
    messages.push(data.message);
  } else if (Array.isArray(data) && data[0]['description'] !== undefined) {
    data.forEach(e => messages.push(e.description));
  } else {
    messages.push(res)
  }

  return messages;

}

export function getSimpleOperatorIcon(slug: string): string {
  try {
    return require(`@/assets/icons/operators/${slug}.png`);
  } catch (error) {
    return require(`@/assets/logo.png`);
  }
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}


export const buildUrlParams = function(obj : any, prefix?: string): string {
  const str = [];
  for (const p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
      buildUrlParams(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}
