import Vue from 'vue'
import Vuex from 'vuex'
import { AuthModule } from './modules/AuthModule'
import { GlobalStateModule } from './modules/GlobalStateModule'
import { SalesModule } from './modules/SalesModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    sales: SalesModule,
    global: GlobalStateModule,
  },
  plugins: [],
})
