import { ChangePasswordRequest } from "@/models/interfaces/requests/ChangePasswordRequest";
import { PutUpdateUserRequest } from "@/models/interfaces/requests/PutUpdateUserRequest";
import { User } from "@/models/interfaces/User";
import { AxiosResponse } from "axios";
import api from "./axios";

export function GetUser(): Promise<AxiosResponse<User, any>> {
  return api.get<User>('v1/user');
}

export function PutUpdateUser(body: PutUpdateUserRequest): Promise<AxiosResponse<any, any>> {
  return api.put(`v1/users/${body.id}`, body);
}


export function PostUpdatePassword(body: ChangePasswordRequest): Promise<AxiosResponse<any, any>> {
  return api.post(`v1/user/changepassword`, body);
}


export function PostRequestActivateAccount(): Promise<AxiosResponse<any, any>> {
  return api.post('v1/validatephone');
}

export function PostActivateAccount(code: string): Promise<AxiosResponse<any, any>> {
  // console.log(code);
  return api.post('v1/validatephonecode', { code });
}

export function PostFcmToken(token: string): Promise<AxiosResponse<any, any>> {
  return api.post('v1/user/refreshtoken', { token });
}
