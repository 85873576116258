





import Vue from 'vue'
import { VMain } from 'vuetify/lib/components'

export default Vue.extend({
  name: 'blank',
  components: {
    VMain,
  },
  data: () => ({}),
})
