import { Module } from 'vuex';

export interface State {
  globalLoading: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GlobalStateModule: Module<State, any> = {
  namespaced: true,
  state: (): State => ({
    globalLoading: false,
  }),
  getters: {
    isAppLoading: state => state.globalLoading
  },
  mutations: {
    setAppLoading(state, status: boolean) {
      state.globalLoading = status;
    },
  },
  actions: {
    async setAppLoading({ commit }, status: boolean) {
      commit('setAppLoading', status)
    },
  }
}
