import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueGtag from 'vue-gtag'

// modules
import Root from './modules/root'
import settings from './modules/settings'
import sales from './modules/sales'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...Root,
    ...settings,
    ...sales,
  ]
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/userIsAuthenticated'];
  if (to.meta?.requiresAuth) {
    if (isAuthenticated || to.name == 'Login') {
      next()
    } else {
      router.push("/login")
    }
  } else {
    if (to.meta?.redirectIfLogged && isAuthenticated) {
      router.push("/")
      return;
    }
    next()
  }
});

Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  release: "recargas-pwa@" + process.env.npm_package_version,
  dsn: 'https://8b6e67f2ec664d29a61d577107be766a@o1113624.ingest.sentry.io/6384786',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'recargasrd.com', 'recharges.beta.banmovil.com', /^\//],
    }),
  ],
  logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

if (process.env.NODE_ENV == 'production') {
  Vue.use(VueGtag, {
    config: { id: 'G-G0XLVZRXBC' },
    appName: 'RecargasRD PWA',
    pageTrackerScreenviewEnabled: true
  }, router);
}

export default router
