/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoginRequest } from "@/models/interfaces/requests/LoginRequest";
import { PostPasswordResetRequest } from "@/models/interfaces/requests/PostPasswordResetRequest";
import { LoginResponse } from "@/models/interfaces/responses/LoginResponse";
import { AxiosResponse } from "axios";
import api from "./axios";

export function PostVerifyPhone(phone: string): Promise<AxiosResponse> {
  return api.post('v1/validateexistphone', { phone });
}

export function PostRequestPasswordReset(email: string): Promise<AxiosResponse>{
  return api.post('v1/forgot-password',{ email });
}

export function PostPasswordReset(requestData: PostPasswordResetRequest): Promise<AxiosResponse>{
  return api.post('v1/reset-password', requestData)
}

export function login(data: LoginRequest): Promise<AxiosResponse<LoginResponse, any>> {
  return api.post<LoginResponse>('login', data);
}
