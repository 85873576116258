import Vue from "vue";
import moment from "moment";


const currency = (value: number) => {
  return `RD$ ${value.toLocaleString("en-US")}`
}

const date = (value:string) =>{
  return moment(value).format("DD/MM/YYYY");
}

const dateTime = (value:string) =>{
  return moment(value).format("DD/MM/YYYY hh:MM:SS");
}

const phoneMask = (value: string) => {
  let newValue = value;

  let x = null;

  if (value.length <= 10) {
    x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  }

  if (x) {
    newValue = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  }

  return newValue;
}

export default function installFilters() {
  Vue.filter("currency", currency);
  Vue.filter("date", date);
  Vue.filter("datetime",dateTime)
  Vue.filter("phoneMask", phoneMask)
}
