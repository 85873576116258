


























































/* eslint-disable vue/no-unused-components */
import Vue, { VueConstructor } from "vue";
import { GetIsIos, GetIsStandalone } from "@/common/utils";
import WithAppBar from "./layouts/WithAppBar.vue";
import Blank from "./layouts/Blank.vue";
import { VApp } from "vuetify/lib/components";
import { DEFAULT_LAYOUT } from "@/common/constants/layouts";
import moment from "moment";
import TheSplashcreen from "./components/TheSplashcreen.vue";
import { mapGetters } from "vuex";
import AsyncDialog from "./components/AsyncDialog.vue";
// import setupNotifications from "@/helpers/notifications";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: any;
    }
  >
).extend({
  name: "App",
  metaInfo: {
    title: "Bienvenido",
    titleTemplate: "%s | RECARGAS - RD",
  },
  components: {
    WithAppBar,
    Blank,
    VApp,
    TheSplashcreen,
    AsyncDialog,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("global", ["isAppLoading"]),
  },
  data() {
    return {
      isAppLoaded: false,
      showInstallPrompt: false,
      refreshing: false,
    };
  },
  created() {
    navigator.serviceWorker?.addEventListener("controllerchange", () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  mounted() {
    this.$root.$on("user:logout", () => {
      this.$refs.currentPage.$destroy();
    });
    this.$nextTick(async () => {
      if (this.user) {
        // console.log("configurando notificaciones");
        // const success = await setupNotifications();
        // console.log("success: ", success);
      }
    });
    // this.$root.$on("user:logged", () => setupNotifications());
  },
  // watch:{
  //   $route (to: RouteRecord, from){
  //     console.log('prueba: ',this.$refs.prueba)
  //   },
  // },
  methods: {
    GetIsIos,
    GetIsStandalone,
    getLayout() {
      return this.$route.meta?.layout || DEFAULT_LAYOUT;
    },
    verifyInstallDialog() {
      var canShowInstallation = true;
      const remindInstallAt = localStorage.getItem("remind-install-at");
      if (remindInstallAt) {
        canShowInstallation =
          moment().toDate() >= moment(remindInstallAt).toDate();
      }
      if (canShowInstallation) {
        const intervalKey = setInterval(() => {
          const canShowOnIos = !GetIsStandalone() && GetIsIos();
          if ((window as any).pwaPrompt || canShowOnIos) {
            this.showInstallPrompt = true;
            clearInterval(intervalKey);
          }
        }, 2000);
      }
    },
    handleRemindLater() {
      localStorage.setItem(
        "remind-install-at",
        moment().add(1, "days").toString()
      );
      this.showInstallPrompt = false;
    },
    handleInstall() {
      this.showInstallPrompt = false;
      (window as any).pwaPrompt.prompt();
    },
    handleLoadCompleted() {
      if (!this.user && this.$route.name != "Login") {
        this.$router.replace("/login");
      }
      this.isAppLoaded = true;
      this.verifyInstallDialog();

      document.addEventListener("swUpdated", this.showUpdateAvailable, {
        once: true,
      });
    },
    showUpdateAvailable(event: any) {
      this.$refs.asyncDialog
        .open({
          title: "Buenas noticias",
          content:
            "Hay una nueva actualización disponible, para continuar debemos actualizar la página",
          okBtnText: "Actualizar",
          cancelBtnText: "Ahora no",
        })
        .then((update: boolean) => {
          if (update) {
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!event.detail || !event.detail.waiting) return;
            // Send message to SW to skip the waiting and activate the new SW
            event.detail.waiting.postMessage({ type: "SKIP_WAITING" });
            // this.$router.go(0);
          } else {
            setTimeout(() => this.showUpdateAvailable(event), 5 * 60 * 1000);
          }
        });
    },
  },
});
