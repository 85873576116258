


































import { sleep } from "@/common/utils";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  name: "the-splashcreen",
  metaInfo: {
    title: 'Cargando..',
    titleTemplate: '%s',
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  mounted() {
    this.initApp();
  },
  computed: mapGetters('auth', ['user']),
  methods: {
    ...mapActions('auth', ['reloadUser', 'logout']),
    async initApp() {
      if (this.user.id === undefined) {
        // await sleep(1200)
        this.$emit('completed')
        return;
      }
      try {
        await this.reloadUser(false);
        this.$emit("completed");
      } catch (err: any) {
        if (err.response) {
          if ([401, 403].includes(err.response.status)) {
            await this.logout();
            setTimeout(() => {
              this.$emit("completed");
            }, 1200);
          }
          return;
        }
        this.isModalOpen = true;
      }
    },
    handleRetry() {
      this.isModalOpen = false;
      this.initApp();
    },
  },
});
