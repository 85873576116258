








































import Vue from 'vue'

export default Vue.extend({
  name: 'async-dialog',
  props: ['title'],
  data: () => ({
    dialog: false,
    options: {
      width: 400,
      zIndex: 200,
      title: '',
      content: '',
      okBtnText: 'Aceptar',
      cancelBtnText: 'Cancelar'
    },
    resolve: (a: any) => console.log(a),
    reject: (a: any) => console.log(a),
  }),

  methods: {
    open(options: any) {
      this.dialog = true;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve : any, reject: any) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  },

  // provide: function() {
  //   return { agree: this.agree, cancel: this.cancel };
  // }
})
