/* eslint-disable @typescript-eslint/no-explicit-any */

import { getRechargeOperators } from '@/api/recharges'
import { Operator } from '@/models/interfaces/Operator'
import { Recharge } from '@/models/interfaces/Recharge'
import { Module } from 'vuex'

export interface State {
  operators: Operator[],
  operator: any,
  phoneNumber: string,
  amount: number|string,
  percentage: number
}

export const SalesModule: Module<State, any> = {
  namespaced: true,
  state: (): State => ({
    operators: [],
    operator: null,
    phoneNumber: '',
    amount: 0,
    percentage: 0
  }),
  getters: {
    selectedOperator: state => state.operator,
    operators: state => state.operators,
    phone: state => state.phoneNumber,
    amount: state => state.amount,
    percentage: state => state.percentage,
  },
  mutations: {
    setOperator(state, operator) {
      state.operator = operator
    },
    setOperators(state, operators) {
      state.operators = operators
    },
    setRechargeData(state, data) {
      state.phoneNumber = data.phone_number;
      state.amount = data.amount;
      state.percentage = data.percentage;
      if (data.operator !== undefined) {
        state.operator = data.operator;
      }
    }
  },
  actions: {
    selectOperator({ commit }, operator: any) {
      commit('setOperator', operator)
    },
    async loadOperators({ commit }) {
      const response = await getRechargeOperators();
      commit('setOperators', response.data);
    },
    clearSelectedOperator({ commit }) {
      commit('setOperator', null)
    },
    setRecharge({ commit }, recharge: Recharge) {
      commit('setRechargeData', recharge);
    }
  }
}
