import { RouteConfig } from 'vue-router'
import { loadView } from '@/helpers/asyncLoaders'
import { DEFAULT_LAYOUT } from '@/common/constants/layouts'

export default [
  {
    path: '/sales',
    name: 'Sales',
    component: loadView('Sales/Index.vue'),
    meta: {
      layout: DEFAULT_LAYOUT,
      title: 'Area de ventas',
      drawer: true,
      requiresAuth: true,
      hasTabs: false,
      canGoBack: false,
      hasActions: true,
    },
  },
  {
    path: '/reports/sales',
    name: 'SalesReport',
    component: loadView('Reports/SalesReport.vue'),
    meta: {
      layout: DEFAULT_LAYOUT,
      title: 'Reporte de ventas',
      drawer: true,
      requiresAuth: true,
      hasTabs: false,
      canGoBack: false,
      hasActions: true,
    },
  },
] as Array<RouteConfig>
