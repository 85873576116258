import { RouteConfig } from 'vue-router'
import { loadView } from '@/helpers/asyncLoaders'
import { DEFAULT_LAYOUT, BLANK_LAYOUT } from '@/common/constants/layouts'

export default [
  {
    path: '/',
    name: 'Home',
    component: loadView('Home.vue'),
    meta: {
      layout: DEFAULT_LAYOUT,
      title: 'Inicio',
      drawer: true,
      requiresAuth: true
    }
  },
  {
    path: '/notifications',
    name: 'Notificaciones',
    component: loadView('Notifications/Index.vue'),
    meta: {
      layout: DEFAULT_LAYOUT,
      title: 'Notificaciones',
      drawer: true,
      requiresAuth: true
    }
  },
  {
    path: '/rates',
    name: 'Tarifas',
    component: loadView('Rates.vue'),
    meta: {
      layout: DEFAULT_LAYOUT,
      title: 'Tarifas',
      drawer: true,
      requiresAuth: true
    }
  },
  {
    path: '/help',
    name: 'Ayuda',
    component: loadView('Help.vue'),
    meta: {
      layout: DEFAULT_LAYOUT,
      title: 'Ayuda',
      drawer: true,
      requiresAuth: true
    }
  },
  {
    path: '/recover-password',
    name: 'Validar codigo',
    component: loadView('Auth/PasswordRecovery.vue'),
    meta: {
      title: 'Validar codigo',
      redirectIfLogged: true,
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: loadView('Auth/Login.vue'),
    meta: {
      title: 'Iniciar sesión',
      redirectIfLogged: true,
      requiresAuth: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: loadView('Auth/Register.vue'),
    meta: {
      title: 'Registrarse',
      canGoBack: true,
      redirectIfLogged: true
    }
  }
] as Array<RouteConfig>
