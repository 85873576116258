import { RouteConfig } from 'vue-router'
import { loadView } from '@/helpers/asyncLoaders'
import { DEFAULT_LAYOUT, BLANK_LAYOUT } from '@/common/constants/layouts'

export default [
  {
    path: '/settings',
    name: 'Configuraciones',
    component: loadView('Settings/Index.vue'),
    meta: {
      layout: DEFAULT_LAYOUT,
      title: 'Configuracion',
      drawer: true,
      requiresAuth: true
    }
  },

  {
    path: '/profile',
    name: 'Perfil',
    component: loadView('Settings/Profile.vue'),
    meta: {
      layout: DEFAULT_LAYOUT,
      title: 'Perfil',
      canGoBack: true,
      requiresAuth: true
    }
  },

  {
    path: '/change-password',
    name: 'Cambiar contraseña',
    component: loadView('Settings/ChangePassword.vue'),
    meta: {
      layout: DEFAULT_LAYOUT,
      title: 'Cambiar contraseña',
      canGoBack: true,
      requiresAuth: true
    }
  },

] as Array<RouteConfig>
