import { buildUrlParams } from "@/common/utils";
import { Operator } from "@/models/interfaces/Operator";
import { Recharge } from "@/models/interfaces/Recharge";
import { User } from "@/models/interfaces/User";
import { AxiosResponse } from "axios";
import api from "./axios";
import { BasePaginationResponse } from "@/models/interfaces/responses/BasePaginationResponse";

export function getRechargeOperators(): Promise<AxiosResponse<Operator[], any>> {
  return api.get('v1/recharge/operators');
}

export function postRecharge(recharge: Recharge): Promise<AxiosResponse<any, any>> {
  return api.post(`v1/recharge/send`, recharge);
}

export function getMyRecharges(page: number, operators: Operator[] = [], dateFrom = null, dateTo = null): Promise<AxiosResponse<BasePaginationResponse<Recharge>, any>> {
  const filters: { page: number, operator_id?: Operator[], dateFrom?: string | null, dateTo?: string | null } = { page };

  if (operators.length > 0) {
    filters['operator_id'] = operators;
  }

  if (dateTo != null) {
    filters.dateTo = dateTo;
  }

  if (dateFrom != null) {
    filters.dateFrom = dateFrom;
  }

  return api.get(`v1/recharge/salereport`, { params: filters });
}
