





















































































import Vue from "vue";

import { VList, VListItem, VListItemGroup } from "vuetify/lib/components";
import { mapActions, mapMutations, mapState } from "vuex";

export default Vue.extend({
  props: {
    value: Boolean,
  },
  computed: {
    ...mapState('auth', ['user']),
    drawer: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean) {
        this.$emit("input", val);
      },
    },
    userFullName: {
      get() {
        let fullName = '';
        if (this.user) {
          fullName = this.user.firstname + ' ' +this.user.lastname
        }

        return fullName;
      }
    },
    userAvatarText: {
      get() {
        if (! this.user) return '🇩🇴';
        const letters: string = this.user.firstname.split('')[0] + this.user.lastname.split('')[0];
        return letters;
      },
    },
    userBalance() {
      let balance = '0.00';

      // if (this.user) {
      //   balance = this.user.balance?.balance
      // }

      return balance;
    }
  },
  components: {
    VList,
    VListItem,
    VListItemGroup,
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('global', ['setAppLoading']),
    async handleLogout() {
      this.drawer = false;
      this.setAppLoading(true);
      this.$root.$emit('user:logout');
      await this.logout();
      setTimeout(() => {
        this.$router.push('/login');
        this.setAppLoading(false);

      }, 600);
    },
  },
});
